export const ApplicationCasesTypes = {
  MY_CASES: { key: "MY_CASES", label: "My Cases" },
  ALL_CASES: { key: "ALL_CASES", label: "All Cases" },
  UNALLOCATED_CASES: { key: "UNALLOCATED_CASES", label: "Unallocated Cases" },
  SENT_FOR_APPROVAL: { key: "SENT_FOR_APPROVAL", label: "Sent For Approval" },
  TOPUP_CASES: { key: "TOPUP_CASES", label: "Top Up Cases" },
};

export const camStagesDisplayNames = {
  CAM_REVIEW: "CAM Review",
  DOCUMENTS_REQUESTED: "Documents Requested",
  SENT_FOR_APPROVAL: "Sent For Approval",
  CAM_APPROVED: "CAM Approved",
  CAM_REJECTED: "CAM  Rejected",
  APPROVED_AND_SENT_TO_HOC: "Approved and Sent to HOC",
};

export const camStages = {
  CAM_REVIEW: "CAM_REVIEW",
  DOCUMENTS_REQUESTED: "DOCUMENTS_REQUESTED",
  SENT_FOR_APPROVAL: "SENT_FOR_APPROVAL",
  CAM_APPROVED: "CAM_APPROVED",
  CAM_REJECTED: "CAM_REJECTED",
  APPROVED_AND_SENT_TO_HOC: "APPROVED_AND_SENT_TO_HOC",
};

export const CMSListTypes = {
  ALLOCATED_APPLICATIONS_LIST: "Allocated Applications",
  OTHER_APPLICATIONS_LIST: "Other Applications",
  COMMENTS_LIST: "Comments",
  DOCUMENT_REQUEST_LIST: "Documents Request",
  TEAM_MEMBERS_LIST: "Team Members",
};

export const DocumentKeyNameMappings = {
  sanctionLetter: { displayName: "Sanction Letter", category: "UNIVERSITY" },
  visa: { displayName: "Visa", category: "UNIVERSITY" },
  I20_Form: { displayName: "I20 Form", category: "UNIVERSITY" },
  pan: { displayName: "Pan Card", category: "PERSONAL" },
  passport_front: { displayName: "Passport Front", category: "PERSONAL" },
  passport_back: { displayName: "Passport Back", category: "PERSONAL" },
  salary_slip: { displayName: "Salary Slip", category: "FINANCIAL" },
  bankStatementApplicant: {
    displayName: "Applicant's Bank Statement",
    category: "FINANCIAL",
  },
  bankStatementCoSigner: {
    displayName: "Cosigner's Bank Statement",
    category: "FINANCIAL",
  },
  it_return: { displayName: "IT Return", category: "FINANCIAL" },
  it_return1: { displayName: "IT Return 1", category: "FINANCIAL" },
  it_return2: { displayName: "IT Return 2", category: "FINANCIAL" },
  gst_return: { displayName: "GST Return", category: "FINANCIAL" },
  creditReportApplicant: {
    displayName: "Applicant's Credit Report",
    category: "FINANCIAL",
  },
  creditReportCosigner: {
    displayName: "Cosigner's Credit Report",
    category: "FINANCIAL",
  },
  resdential_proof: { displayName: "Residential Proof", category: "FINANCIAL" },
  balanceSheet: { displayName: "Balance Sheet", category: "FINANCIAL" },
  UGTranscript: { displayName: "UG Transcript", category: "EDUCATIONAL" },
  PGTranscript: { displayName: "PG Transcript", category: "EDUCATIONAL" },
  xMarksheet: { displayName: "10th Marksheet", category: "EDUCATIONAL" },
  xiiMarksheet: { displayName: "12th Marksheet", category: "EDUCATIONAL" },
  GRE_Scorecard: { displayName: "GRE Scorecard", category: "EDUCATIONAL" },
  GMAT_Scorecard: { displayName: "GMAT Scorecard", category: "EDUCATIONAL" },
  IELTS_Scorecard: { displayName: "IELTS Scorecard", category: "EDUCATIONAL" },
  PTE_Scorecard: { displayName: "PTE Scorecard", category: "EDUCATIONAL" },
  TOEFL_Scorecard: { displayName: "TOEFL Scorecard", category: "EDUCATIONAL" },
  applicantSign: { displayName: "Applicant's Sign", category: "PERSONAL" },
  "borrower-agreement": {
    displayName: "Borrower Agreement",
    category: "UNIVERSITY",
  },
  cosignerSign: { displayName: "Cosigner's Sign", category: "PERSONAL" },
  ssn: { displayName: "SSN", category: "PERSONAL" },
  photograph: { displayName: "Photograph", category: "PERSONAL" },
  achForm: { displayName: "ACH Form", category: "UNIVERSITY" },
  foreclosureStatement: {
    displayName: "Foreclosure Statement",
    category: "PERSONAL",
  },
  extraDocument: { displayName: "Extra Document", category: "EXTRA_DOCUMENT" },
  other: { displayName: "Other Document", category: "OTHER" },
  loanStatement: { displayName: "Loan Statement", category: "FINANCIAL" },
  loanDataFile: { displayName: "Loan Data File", category: "FINANCIAL" },
  soa: { displayName: "SOA", category: "OTHER" },
  w2Form: { displayName: "W2 Form", category: "OTHER" },
  collegeAdmitCard: {
    displayName: "College Admit Card",
    category: "UNIVERSITY",
  },
  recommendationEmail: {
    displayName: "Recommendation Email",
    category: "UNIVERSITY",
  },
  fiReport: { displayName: "FI Report", category: "OTHER" },
  rcuReport: { displayName: "RCU Report", category: "OTHER" },
  studentDisbursementEmail: {
    displayName: "Student Disbursement Email",
    category: "OTHER",
  },
  disbursementEmailRaised: {
    displayName: "Disbursement Email Raised",
    category: "OTHER",
  },
  feeInvoice: { displayName: "Fee Invoice", category: "OTHER" },
  feePaidReceipt: { displayName: "Fee Paid Receipt", category: "OTHER" },
  transcripts: { displayName: "Transcripts", category: "EDUCATIONAL" },
  enrollmentVerification: {
    displayName: "Enrollment Verification",
    category: "OTHER",
  },
  roiApproval: { displayName: "ROI Approval", category: "OTHER" },
  airTicket: { displayName: "Air Ticket", category: "FINANCIAL" },
  sableAccountDetails: {
    displayName: "Sable Account Details",
    category: "OTHER",
  },
  usAddressProof: { displayName: "US Address Proof", category: "OTHER" },
  ugProvisionalCertificate: {
    displayName: "UG Provisional Certificate",
    category: "OTHER",
  },
  provisionalUSCerificate: {
    displayName: "US Provisional Certificate",
    category: "OTHER",
  },
  offerLetter: { displayName: "Offer Letter", category: "OTHER" },
  appointment_letter: { displayName: "Appointment Letter", category: "OTHER" },
  ppOrder: { displayName: "PPO Order", category: "FINANCIAL" },
  topupInvoice: {
    displayName: "Tuition Fee Invoice",
    category: "TOPUP_DOCUMENTS",
  },
  topupLatestTranscript: {
    displayName: "Latest Transcript",
    category: "TOPUP_DOCUMENTS",
  },
  topupLastSemesterReceipt: {
    displayName: "Last Semester Receipt",
    category: "TOPUP_DOCUMENTS",
  },
  topupRequestMail: {
    displayName: "Top-Up Request",
    category: "TOPUP_DOCUMENTS",
  },
  "borrower-agreement-topup": {
    displayName: "Signed Top-up Contract",
    category: "TOPUP_DOCUMENTS",
  },
  topupAddDoc1: {
    displayName: "Top-Up Add Doc 1",
    category: "TOPUP_DOCUMENTS",
  },
  topupAddDoc2: {
    displayName: "Top-Up Add Doc 2",
    category: "TOPUP_DOCUMENTS",
  },
  topupAddDoc3: {
    displayName: "Top-Up Add Doc 3",
    category: "TOPUP_DOCUMENTS",
  },
  samsReport: {
    displayName: "SAMS Report",
    category: "SANCTION_APPROVAL",
  },
  ofacReport: {
    displayName: "OFAC Report",
    category: "SANCTION_APPROVAL",
  },
};

export const DocumentTypeMappings = {
  PERSONAL: { key: "PERSONAL", label: "Personal" },
  EDUCATIONAL: { key: "EDUCATIONAL", label: "Educational" },
  FINANCIAL: { key: "FINANCIAL", label: "Financial" },
  UNIVERSITY: { key: "UNIVERSITY", label: "University" },
  OTHER: { key: "OTHER", label: "Other" },
  EXTRA_DOCUMENT: { key: "EXTRA_DOCUMENT", label: "ExtraDocument" },
  TOPUP_DOCUMENTS: { key: "TOPUP_DOCUMENTS", label: "TopUpDocuments" },
  SANCTION_APPROVAL: {
    key: "SANCTION_APPROVAL",
    label: "SanctionApprovalDocuments",
  },
};

export const DocumentApplicantTypeMapping = {
  studentPersonalDocuments: "APPLICANT",
  studentEducationalDocuments: "APPLICANT",
  studentFinancialDocuments: "APPLICANT",
  studentUniversityDocuments: "APPLICANT",
  studentOtherDocuments: "APPLICANT",
  studentSanctionApprovalDocuments: "APPLICANT",
  cosignerPersonalDocuments: "COSIGNER",
  cosignerFinancialDocuments: "COSIGNER",
  cosignerOtherDocuments: "COSIGNER",
  cosignerSanctionApprovalDocuments: "COSIGNER",
  extraCosignerPersonalDocuments: "EXTRA_COSIGNER",
  extraCosignerFinancialDocuments: "EXTRA_COSIGNER",
  extraCosignerOtherDocuments: "EXTRA_COSIGNER",
  extraCosignerSanctionApprovalDocuments: "EXTRA_COSIGNER",
  otherDocuments: "OTHER",
  applicationExtraDocuments: "OTHER",
};

export const ApplicantTypes = {
  STUDENT: { key: "APPLICANT", label: "Student" },
  COSIGNER: { key: "COSIGNER", label: "Cosigner" },
  EXTRA_COSIGNER: { key: "EXTRA_COSIGNER", label: "Extra Cosigner" },
  OTHER: { key: "OTHER", label: "Other" },
};

export const allPossibleStudentPersonalDocuments = [
  {
    keyName: "photograph",
    label: "Photograph",
  },
  {
    keyName: "pan",
    label: "PAN",
  },
  {
    keyName: "passport_front",
    label: "Passport Front",
  },
  {
    keyName: "passport_back",
    label: "Passport Back",
  },
];

export const allPossibleStudentEducationalDocuments = [
  {
    keyName: "xMarksheet",
    label: "10th Marksheet",
  },
  {
    keyName: "xiiMarksheet",
    label: "12th Marksheet",
  },
  {
    keyName: "UGTranscript",
    label: "UG Transcript",
  },
  {
    keyName: "PGTranscript",
    label: "PG Transcript",
  },
  {
    keyName: "GRE_Scorecard",
    label: "GRE Scorecard",
  },
  {
    keyName: "TOEFL_Scorecard",
    label: "TOEFL Scorecard/IELTS",
  },
  {
    keyName: "GMAT_Scorecard",
    label: "GMAT",
  },
];

export const allPossibleStudentFinancialDocuments = [
  {
    keyName: "it_return",
    label: "IT Return",
  },
  {
    keyName: "bankStatementApplicant",
    label: "6 Months Bank Statements",
  },
  {
    keyName: "salary_slip",
    label: "3 months Salary Slips",
  },
];

export const allPossibleStudentUniversityDocuments = [
  {
    keyName: "visa",
    label: "Visa",
  },
  {
    keyName: "I20_Form",
    label: "i20 Form",
  },
  {
    keyName: "collegeAdmitCard",
    label: "College Admit Card",
  },
];

export const allPossibleCosignerPersonalDocuments = [
  {
    keyName: "pan",
    label: "PAN of Cosigner",
  },
  {
    keyName: "resdential_proof",
    label: "Proof of Accomodation",
  },
];

export const allPossibleCosignerFinancialDocuments = [
  {
    keyName: "it_return1",
    label: "IT Returns 1",
  },
  {
    keyName: "it_return2",
    label: "IT Returns 2",
  },
  {
    keyName: "bankStatementCoSigner",
    label: "Cosigner's Bank Statement",
  },
  {
    keyName: "salary_slip",
    label: "Salary Slip",
  },
  {
    keyName: "gst_return",
    label: "GST Return",
  },
  {
    keyName: "ppOrder",
    label: "PPO Document",
  },
];

export const allPossibleExtraCosignerPersonalDocuments = [
  {
    keyName: "pan",
    label: "PAN of Cosigner",
  },
];

export const allPossibleExtraCosignerOtherDocuments = [
  {
    keyName: "ppOrder",
    label: "PPO Order",
  },
];

export const ValidationFieldTypes = {
  GRE: "GRE",
  GMAT: "GMAT",
  TOEFL: "TOEFL",
  IELTS: "IELTS",
  "10TH": "10TH",
  "12TH": "12TH",
  AADHAR: "AADHAR",
  CREDITSCORE: "CREDITSCORE",
  EMAIL: "EMAIL",
  DUOLINGO: "DUOLINGO",
  GMATTOTAL: "GMATTOTAL",
  PHONE: "PHONE",
  GPA: "GPA",
  NONZERO: "NONZERO",
  DATE_OF_BIRTH: "DATE_OF_BIRTH",
  MULTI_SELECT_VALUES: "MULTI_SELECT_VALUES",
  INVALID_YEAR: "INVALID_YEAR",
  PASSING_YEAR: "PASSING_YEAR",
  PASSPORT: "PASSPORT",
  SOFR: "SOFR",
};

export const cmsRoles = {
  OFFICER: "officer",
  MANAGER: "manager",
  HOC: "hoc",
};

export const DocumentExtensionTypes = {
  PDF: "pdf",
  JPEG: "jpeg",
  PNG: "png",
  JPG: "jpg",
  XLSX: "xlsx",
  JFIF: "jfif",
};

export const PaginationTypes = {
  APPLICATIONS: "APPLICATIONS",
  DOCUMENTS: "DOCUMENTS",
};

export const addressTypes = {
  CURRENT: "CURRENT_ADDRESS",
  PERMANENT: "PERMANENT_ADDRESS",
};

export const UserDetailsTypes = {
  PERSONAL_DETAILS: "personalDetails",
  ADDRESS_DETAILS: "addressDetails",
  WORK_DETAILS: "workDetails",
  ADDITIONAL_COMMENTS: "additionalComments",
  AVERAGE_BANK_BALANCE: "averageBankBalance",
  ACADEMIC_DETAILS: "academicDetails",
  WORK_EXPERIENCE: "workexperience",
  REFERENCES: "references",
  UNIVERSITY_DETAILS: "universityDetails",
  UEOFFER: "ueoffer",
  EXTRA_DETAILS: "extraDetails",
  ELIGIBILITY: "eligibility",
  UPDATED_OFFER: "updatedOffer",
  PERSONAL_DISCUSSION: "personalDiscussion",
  APPROVAL_DETAILS: "approvalDetails",
  DEVIATIONS: "deviations",
  MITIGATES: "mitigates",
  OVERALL_COMMENTS: "overallComments",
  SANCTION_CONDITIONS: "sanctionConditions",
  APPLICATION_SUMMARY: "applicationSummary",
  OTHER_APPLICATIONS: "otherApplications",
  DEDUPED_APPLICATIONS: "dedupedApplications",
  FAMILY_EXPOSURE: "familyExposure",
  OFAC_STATUS: "ofac-status",
};

export const UserTypes = {
  EXTRA_COSIGNER: "extraCosigner",
  COSIGNER: "cosigner",
  OTHER: "other",
  APPLICANT: "student",
};

export const StudentHighestEducation = {
  UG: "UG",
  PG: "PG",
  DIPLOMA: "DIPLOMA",
};

export const CamTabs = {
  SUMMARY: "summary",
  CAM: "cam",
  TOPUP: "topUp",
  DOCUMENTREQUESTBUCKET: "documentRequestBucket",
  SAMS: "sams",
};

export const deduplicationKeyTextMapper = {
  applicant: {
    applicantAadharMatch: "Aadhar Card",
    applicantPanMatch: "Pan Card",
    applicantPhoneMatch: "Phone Number",
  },
  cosigner: {
    cosignerAadharMatch: "Aadhar Card",
    cosignerPanMatch: "Pan Card",
    cosignerPhoneMatch: "Phone Number",
  },
};

export const relationshipTypes = {
  OTHER: "OTHER",
};

export const employmentTypes = {
  SALARIED: "SALARIED",
  SELFEMPLOYED: "SELFEMPLOYED",
  PENSIONER: "PENSIONER",
};

export const subEmploymentTypes = {
  GOVERNMENT: "GOVERNMENT",
};

export const opsRoleTypes = {
  CREDIT_MANAGER: "Credit Manager",
};

export const CreditRejectionReasonsEnum = {
  CREDIT_APPLICATION_INCOMPLETE: "CREDIT_APPLICATION_INCOMPLETE",
  INCOME_INSUFFICIENT: "INCOME_INSUFFICIENT",
  EXCESSIVE_OBLIGATIONS: "EXCESSIVE_OBLIGATIONS",
  UNABLE_TO_VERIFY_INCOME: "UNABLE_TO_VERIFY_INCOME",
  TEMPORARY_RESIDENCE: "TEMPORARY_RESIDENCE",
  DELINQUENT_PAST_OR_PRESENT_CREDIT_OBLIGATIONS:
    "DELINQUENT_PAST_OR_PRESENT_CREDIT_OBLIGATIONS",
  COLLECTION_ACTION_OR_JUDGEMENT: "COLLECTION_ACTION_OR_JUDGEMENT",
  FORECLOSURE_OR_REPOSSESSION: "FORECLOSURE_OR_REPOSSESSION",
  NO_CREDIT_FILE: "NO_CREDIT_FILE",
};

export const CreditRejectionReasonsDescription = {
  [CreditRejectionReasonsEnum.CREDIT_APPLICATION_INCOMPLETE]: {
    title: "Credit application incomplete",
    helperText: "Pending docs not received, student not responding etc",
  },
  [CreditRejectionReasonsEnum.INCOME_INSUFFICIENT]: {
    title: "Income insufficient for amount of credit requested",
    helperText: "Very low income or income proof not available/documented",
  },
  [CreditRejectionReasonsEnum.EXCESSIVE_OBLIGATIONS]: {
    title: "Excessive obligations in relation to income",
    helperText:
      "Higher obligations of student/ co signer in relation to income",
  },
  [CreditRejectionReasonsEnum.UNABLE_TO_VERIFY_INCOME]: {
    title: "Unable to verify income",
    helperText: "Income proof not available/documented",
  },
  [CreditRejectionReasonsEnum.TEMPORARY_RESIDENCE]: {
    title: "Temporary residence",
    helperText: "No owned residence and no proof",
  },
  [CreditRejectionReasonsEnum.DELINQUENT_PAST_OR_PRESENT_CREDIT_OBLIGATIONS]: {
    title: "Delinquent past or present credit obligations with others",
    helperText: "Negative CR",
  },
  [CreditRejectionReasonsEnum.COLLECTION_ACTION_OR_JUDGEMENT]: {
    title: "Collection action or judgement",
    helperText: "Negative CR, past repayment issues as in loans settled",
  },
  [CreditRejectionReasonsEnum.FORECLOSURE_OR_REPOSSESSION]: {
    title: "Foreclosure or repossession",
    helperText: "Negative CR, Sarfaesi notice issued",
  },
  [CreditRejectionReasonsEnum.NO_CREDIT_FILE]: {
    title: "No Credit file",
    helperText: "No previous credit history, CR -1/NTC",
  },
};
